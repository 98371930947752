import {
  Grid,
  ListItem,
  ListItemAvatar,
  colors,
  Avatar,
  ListItemText,
  List,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import { Image } from '@/src/components/atoms';
import { SectionHeader, TypedText } from '@/src/components/molecules';

const useStyles = makeStyles(() => ({
  typed: { fontWeight: 'bold' },
  image: {
    maxWidth: 400,
    height: 'auto',
    width: '100%',
  },
}));

export default function Setup({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          xs={12}
          sm={5}
          data-aos="fade-up">
          <div className={classes.image}>
            <Image src="/images/illustrations/platform/feature-2.svg" alt="Setup" lazy={false} />
          </div>
        </Grid>
        <Grid item xs={12} sm={7} data-aos="fade-up">
          <SectionHeader
            title={
              <>
                <span>Comece a vender em apenas </span>
                <TypedText
                  component="span"
                  variant="h4"
                  color="primary"
                  className={classes.typed}
                  typedProps={{
                    strings: ['5 dias.'],
                    typeSpeed: 100,
                    loop: true,
                  }}
                />
              </>
            }
            subtitle="Veja como:"
            align="left"
            disableGutter
          />
          <List>
            {[
              'Escolha o nome e o link da sua loja',
              'Cadastre seus produtos ou serviços e seus preços',
              'Customize as formas de pagamento: Pix, boleto, cartão, boleto + cartão',
              'Defina o tipo de entrega: na instituição ou na casa dos responsáveis',
              'Cadastre o comissionamento dos seus parceiros e, comece a vender',
            ].map((item, index) => (
              <ListItem key={index.toString()} data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: colors.lightBlue[700] }}>{index + 1}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}

Setup.propTypes = {
  className: PropTypes.string,
};
